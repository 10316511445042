import React from "react"
import Layout from "../components/Layout"
import indexStyle from "../scss/index.module.scss"
import formStyle from "../scss/form.module.scss"

const contact = () => {
  return (
    <Layout>
      <section className={indexStyle.content}>
        <div className={indexStyle.welcome}>
          <div className={indexStyle.innerWelcome}>
            <h3>Contact Us</h3>
            <p>
              We are able to provide professional advice and support alongside a
              no obligation free quotation. If you would like to find out how we
              can help you, simply get in touch and we will take care of the
              rest.
            </p>
            <h2>07876 340 703</h2>
            <h2>info@beechandgreene.co.uk</h2>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              className={formStyle.form}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className={formStyle.name}>
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className={formStyle.email}>
                <label htmlFor="email">Email</label>
                <input type="text" name="email" id="email" />
              </div>
              <div className={formStyle.message}>
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" rows="6" />
              </div>
              <ul className={formStyle.actions}>
                <li>
                  <input
                    className={formStyle.button}
                    type="submit"
                    value="ENQUIRE"
                  />
                </li>
                <li>
                  <input
                    className={formStyle.button}
                    type="reset"
                    value="CLEAR"
                  ></input>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default contact
